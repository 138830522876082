import * as React from "react";

import "../sass/main.scss";
import { Content } from "carbon-components-react";
import ArticleCardComponent from "./articleCard";

// article images

import image from "../images/air-ambulance-edited.jpg";
import image2 from "../images/SIIA_2023_Forum_article.png";

const NewsComponent = () => {
  return (
    <>
      <div>
        <div id="news" className="anchorOffset" />
        <Content className="news">
          <div className="sectionHeader spacing07">Latest News</div>
          <div className="bx--grid">
            <div className="bx--row">
              <div className="bx--col-lg-6 bx--offset-lg-4 spacing07">
                <ArticleCardComponent
                  href="/articles/2023-01-06-air-ambulance-report-requirements"
                  image={image}
                  alt="Air Ambulance"
                  heading="Ready to Submit Your Air Ambulance Claims Report? Reports
      are Due on 3/31/2023"
                  subtitle="Here’s a list of key requirements for health plans and
      TPAs to use as they start preparing to submit their first
      air ambulance claims report."
                />
              </div>
              <div className="bx--col-lg-6">
                <ArticleCardComponent
                  href="/articles/2023-01-11-LaunchPad-Participation-SIIA-Spring-Forum"
                  image={image2}
                  alt="SIIA 2023 Spring Forum"
                  heading="LaunchPad's Mike Orth to Speak on Price Transparency at SIIA's Spring Forum"
                  subtitle="Mike Orth will provide senior healthcare executives everything they need to know on the evolving regulatory and business environment related to price transparency."
                />
              </div>
            </div>
          </div>
        </Content>
      </div>
    </>
  );
};

export default NewsComponent;
