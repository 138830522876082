import React, { useState } from "react";

import axios from "axios";

import {
  Button,
  Content,
  Form,
  InlineNotification,
  NotificationActionButton,
  TextArea,
  TextInput,
} from "carbon-components-react";

const ContactFormComponent = () => {
  const [successAlert, setSuccessAlert] = useState("");
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const sendAnother = () => {
    setSuccessAlert("false");
  };

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/e1ad6261-52e0-4313-b4f8-cf86b6f9ac7a",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, setSuccessAlert("true"), form);
      })
      .catch((r) => {
        handleServerResponse(
          false,
          alert("Oops, something went wrong. Please try again."),
          form
        );
      });
  };

  return (
    <>
      <div id="contact" className="anchorOffset" />
      <Content>
        <div className="bx--grid ">
          <div className="bx--row">
            <div className="bx--col-lg-3" />
            <div className="bx--col-lg-10 whiteBackground">
              <h2 className="spacing09 h2">
                Schedule a consultation. Or ask our experts a question.
              </h2>
              <iframe name="getform" style={{ display: "none" }}></iframe>
              {successAlert === "true" ? (
                <div className="formSubmission">
                  <InlineNotification
                    kind="success"
                    iconDescription="Clear this message."
                    actions={
                      <NotificationActionButton onClick={sendAnother}>
                        Send another
                      </NotificationActionButton>
                    }
                    subtitle={
                      <span>
                        We've received your message and will respond within 24
                        hours.
                      </span>
                    }
                    title="Thank you."
                  />
                </div>
              ) : (
                <Form
                  id="form"
                  //   action="https://getform.io/f/e1ad6261-52e0-4313-b4f8-cf86b6f9ac7a"
                  //   method="POST"
                  target="getform"
                  onSubmit={handleOnSubmit}
                >
                  <TextInput
                    id="name"
                    name="name"
                    className="spacing07"
                    invalidText="Invalid error message."
                    labelText="Name"
                    placeholder="Name"
                    required
                  />
                  <TextInput
                    id="company"
                    name="company"
                    className="spacing07"
                    invalidText="Invalid error message."
                    labelText="Company"
                    placeholder="Company"
                    required
                  />
                  <TextInput
                    id="email"
                    name="email"
                    className="spacing07"
                    invalidText="Invalid error message."
                    labelText="Email"
                    placeholder="Email"
                    type="email"
                    required
                  />
                  <TextArea
                    id="Message"
                    name="message"
                    cols={50}
                    className="spacing09"
                    invalidText="Invalid error message."
                    labelText="Message"
                    placeholder="Type your message."
                    rows={4}
                    required
                  />
                  <Button kind="primary" tabIndex={0} type="submit">
                    Submit
                  </Button>
                </Form>
              )}
            </div>
            <div className="bx--col-lg-3" />
          </div>
        </div>
      </Content>
    </>
  );
};

export default ContactFormComponent;
