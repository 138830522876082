import * as React from "react";
import "../sass/main.scss";
import { Button } from "carbon-components-react";

const ContactComponent = () => {
  return (
    <>
      <div>
        <div className="contact">
          <div className="bx--grid--full-width noRightPadding">
            <div className="bx--row noTopPadding marginZero">
              <div className="bx--col-xlg-5 bx--col-lg-6 bx--col-md-8 bx--col-sm-4 contactTop">
                <h2 className="spacing09 h2">Work with the experts.</h2>
                <p className="spacing12">
                  We have industry-leading expertise in the price transparency
                  regulations&mdash;and deep experience in healthcare strategy,
                  operations, and product. Let's talk about how we can help you
                  fix healthcare.
                </p>
              </div>
              <div className="bx--col-xlg-3 bx--col-lg-1 bx--col-md-0 bx--col-sm-0"></div>
              <div className="bx--col-xlg-8 bx--col-lg-9 bx--col-md-8 bx--col-sm-4 contactImg">
                <Button
                  className="nameAndTitle"
                  kind="secondary"
                  href="https://www.linkedin.com/in/mike-orth"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bold">Mike Orth</div>
                  <div>Founder {"&"} Principal</div>
                  <div>LaunchPad Health</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactComponent;
