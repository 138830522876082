import * as React from "react";

import { ClickableTile } from "carbon-components-react";
import { ArrowRight20 } from "@carbon/icons-react";

const ArticleCardComponent = (props) => {
  return (
    <>
      <ClickableTile href={props.href} renderIcon={ArrowRight20}>
        <div>
          <img
            src={props.image}
            alt={props.alt}
            style={{ width: "100%" }}
            className="spacing05"
          />

          <h3 className="spacing05">{props.heading}</h3>
          <p className="spacing07">{props.subtitle}</p>
        </div>
      </ClickableTile>
    </>
  );
};

export default ArticleCardComponent;
