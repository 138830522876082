import * as React from "react";

import { PrimaryButton, Button } from "carbon-components-react";
import { ArrowRight20 } from "@carbon/icons-react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CoverComponent = () => {
  return (
    <>
      <div className="coverContainer leadSpaceMargin">
        <div className="gradientOverlay" />
        <div className="coverContentContainer">
          <div className="aboveOverlay">
            <h1 className="coverTitle">
              You're fixing healthcare. We're here to help.
            </h1>
          </div>

          <div className="aboveOverlay">
            <h4 className="coverDescription paddingBottom">
              We help health plans and their partners navigate regulations,
              launch new products, and optimize operations.
            </h4>
            <AnchorLink to="/#contact">
              <PrimaryButton renderIcon={ArrowRight20} className="coverButton">
                Schedule a consultation
              </PrimaryButton>
            </AnchorLink>
            <Button
              kind="secondary"
              renderIcon={ArrowRight20}
              className="coverButton"
              href="/transparency-toolkit"
            >
              Price Transparency Toolkit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverComponent;
