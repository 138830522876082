import * as React from "react";
import "../sass/main.scss";
import "../sass/app.scss";

import SEO from "../components/seo";

import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";

import { Content } from "carbon-components-react";
import CoverComponent from "../components/cover";
import NewsComponent from "../components/news";
import ContactComponent from "../components/contact";
import seoIndexImage from "../images/fixing-healthcare.jpg";
import SolutionsComponent from "../components/solutions";
import ContactFormComponent from "../components/contactForm";

const IndexPage = () => {
  return (
    <>
      <SEO
        description="We help health plans and their partners navigate regulations,
        launch new products, and optimize operations."
        image={{ src: seoIndexImage, height: 1200, width: 630 }}
      />
      <div className="greyBackground">
        <header>
          <HeaderComponent />
        </header>
        <CoverComponent />
        <div id="solutions" className="anchorOffset" />
        <Content className="greyBackground">
          <SolutionsComponent />
        </Content>
        <NewsComponent />
        <ContactComponent />
        <ContactFormComponent />
        <footer>
          <FooterComponent />
        </footer>
      </div>
    </>
  );
};

export default IndexPage;
