import * as React from "react";

import { Tile } from "carbon-components-react";
import {
  ReducingCost,
  AuditTrail,
  Justice,
  Process,
} from "@carbon/pictograms-react";

const SolutionsComponent = () => {
  return (
    <>
      <span className="sectionHeader">Solutions</span>
      <div
        className="bx--grid bx--grid-condensed"
        style={{ marginTop: "2rem" }}
      >
        <div className="bx--row">
          <div className="bx--col bx--offset-lg-4">
            <Tile
              className="bx--aspect-ratio bx--aspect-ratio--16x9 spacing07"
              style={{ backgroundColor: "white" }}
            >
              <h3 className="spacing05">Comply with Price Transparency</h3>
              <AuditTrail
                aria-label="Comply with Price Transparency"
                className="spacing09"
              />
            </Tile>
            <Tile
              className="bx--aspect-ratio bx--aspect-ratio--16x9 spacing07"
              style={{ backgroundColor: "white" }}
            >
              <h3 className="spacing05">Stay on Top of New Regulations</h3>
              <Justice
                aria-label="Stay on Top of New Regulations"
                className="spacing09"
              />
            </Tile>
          </div>
          <div className="bx--col">
            <Tile
              className="bx--aspect-ratio bx--aspect-ratio--16x9 spacing07"
              style={{ backgroundColor: "white" }}
            >
              <h3 className="spacing05">Optimize Plan Operations</h3>
              <Process
                aria-label="Optimize Plan Operations"
                className="spacing09"
              />
            </Tile>
            <Tile
              className="bx--aspect-ratio bx--aspect-ratio--16x9"
              style={{ backgroundColor: "white" }}
            >
              <h3 className="spacing05">
                Reduce Spend, Drive Member Satisfaction
              </h3>
              <ReducingCost
                aria-label="Reduce Spend, Drive Member Satisfaction"
                className="spacing09"
              />
            </Tile>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolutionsComponent;
